<template>
  <div class="main-container">

    <Header />

    <Breadcrumb :items="items" title="业务范围" />

    <div class="page-wrapper section-space--inner--100">
      <!--Service section start-->
      <div class="service-section">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="service-item-wrapper">
                <div class="row">
                  <div v-for="(service,index) in $d.serviceList" :key="index" class="col-lg-4 col-md-6 col-12 section-space--bottom--30">
                    <ServiceItem :id="index" :service="service" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Service section end-->
    </div>
    <!--
    <BrandCarousel add-class="grey-bg" /> -->

    <Footer />

    <OffCanvasMobileMenu />

    <!-- back to top start -->
    <back-to-top class="scroll-top" bottom="60px">
      <i class="ion-android-arrow-up" />
    </back-to-top>
    <!-- back to top end -->
  </div>
</template>

<script>
import Header from '@/components/Header'
import Breadcrumb from '../components/Breadcrumb'
import ServiceItem from '../components/ServiceItem'
import Footer from '../components/Footer'
import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu'
export default {
  components: {
    Header,
    Breadcrumb,
    ServiceItem,
    Footer,
    OffCanvasMobileMenu
  },
  data() {
    return {
      items: [
        {
          text: 'Business Scope',
          active: true
        }
      ]
    }
  },
  metaInfo: {
    title: '乌节国际 - 业务范围',
    htmlAttrs: {
      lang: 'zh-cn',
      amp: true
    }
  }
}
</script>

